import React from 'react'

const FileUploader = () => {
  return (
    <>
        <div className="file-card">
            <div className="file-inputs">
                <input type="file" />
            </div>
        </div>
    </>
  )
}

export default FileUploader