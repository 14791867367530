export const reviews = [
    {name: 'Bogdan Rusu',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, ratione, voluptatem cum molestiae hic nam neque culpa eveniet magnam consequatur vitae accusamus ducimus voluptates. Optio consequuntur ipsam autem officia itaque!'
    },
    {
    name: 'Elena Rusu',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, ratione, voluptatem cum molestiae hic nam neque culpa eveniet magnam consequatur vitae accusamus ducimus voluptates. Optio consequuntur ipsam autem officia itaque!'
    },
    {
        name: 'Katerina Rusu',
        review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, ratione, voluptatem cum molestiae hic nam neque culpa eveniet magnam consequatur vitae accusamus ducimus voluptates. Optio consequuntur ipsam autem officia itaque!'
    }
]